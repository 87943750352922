import _ from "lodash";

const required = value => (!!value || value === 0) || "Required."
const requiredMultiSelect = value => !!_.size(value) || "Required.";

const email = value => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!value) {
    return true;
  }

  return pattern.test(value) || "Invalid email format.";
};

const set = field => {
  const rules = [];

  if (_.get(field, "required")) {
    _.get(field, "type") === 'multi-select'
      ? rules.push(requiredMultiSelect)
      : rules.push(required);
  }

  if (_.get(field, "type") === "email") {
    rules.push(email);
  }

  return rules;
};

export default {
  set,
  required,
  email
};
